class OffersApi {
    getRenewOfferData(locale, expiredOfferId) {
        return fetch('/' + locale + '/offers/get-renew-offer-data.json?id=' + expiredOfferId)
            .then(function (response) {
                return response.json();
            });
    }

    getEditOfferData(locale, expiredOfferId) {
        return fetch('/' + locale + '/offers/get-full-offer-data.json?id=' + expiredOfferId)
            .then(function (response) {
                return response.json();
            });
    }

    getCreateOptions(locale) {
        return fetch('/' + locale + '/offers/create-options.json')
            .then(function (response) {
                return response.json();
            });
    }

    createOffer(offerData, locale, csrfToken) {
        return fetch('/' + locale + '/offers/save.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken,
            },
            body: JSON.stringify(offerData),
            redirect: "manual"
        });
    }

    renewOffer(offerData, locale, csrfToken) {
        return fetch('/' + locale + '/offers/renewOffer.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken,
            },
            body: JSON.stringify(offerData),
            redirect: "manual"
        });
    }

    editOffer(offerData, locale, csrfToken) {
        return fetch('/' + locale + '/offers/edit/' + offerData.id + '.json', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken,
            },
            body: JSON.stringify(offerData),
            redirect: "manual"
        });
    }
}

export default new OffersApi();
