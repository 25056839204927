<template>
    <offer-form v-if="renewOfferData"
                :allowed-image-types="allowedImageTypes"
                :max-file-size="maxFileSize"
                :offer="renewOfferData"
                :save-handler="getHandleRenewOffer"
                :locale="locale"></offer-form>
</template>

<script>
import OfferForm from "../OfferForm";
import OffersApi from "../../services/OffersApi";

export default {
    name: 'RenewOffer',
    components: {OfferForm},
    data() {
        return {
            renewOfferData: null
        };
    },
    props: [
        'locale',
        'allowedImageTypes',
        'maxFileSize',
        'expiredOfferId',
        'csrfToken'
    ],
    created() {
        OffersApi.getRenewOfferData(this.locale, this.expiredOfferId).then((response) => {
            this.renewOfferData = response.data;
        });
    },
    methods: {
        getHandleRenewOffer(renewOfferData) {
            return OffersApi.renewOffer(renewOfferData, this.locale, this.csrfToken).then(response => {
                return response.json();
            });
        }
    }
};
</script>

<style scoped>
</style>
