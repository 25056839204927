<template>
    <div :class="{card: !isMobile, 'pt-1 pb-1': isMobile}">
        <h2 :class="{'card-header': !isMobile, 'border-top pt-3': isMobile}" v-bind:id="table + 'Filter'">
            <button class="btn text-secondary w-100" type="button" v-bind:data-toggle="isMobile ? null : 'collapse'"
                    :class="{'pl-0': isMobile}"
                    v-bind:data-target="'#' + table + 'FilterBody'"
                    v-bind:aria-controls="table + 'FilterBody'">
                <div class="d-flex align-items-center justify-content-between w-100">
                    <div>
                        {{ label }}
                    </div>
                    <div v-if="filtersModel?.count > 0" class="bg-secondary text-white text-center"
                         style="border-radius: .25rem; padding: .25em .4em; line-height: 1; font-size: 75%">
                        {{ filtersModel.count }}
                    </div>
                </div>
            </button>
        </h2>
        <div v-bind:id="table + 'FilterBody'"
             class="accordion-collapse" :class="{'collapse' : !isMobile, 'show': isMobile}"
             v-bind:aria-labelledby="table + 'Filter'">
            <div :class="{'card-body': !isMobile, 'pb-3': isMobile}">
                <offer-filter-input v-model:filters="filtersModel"
                                    :options="options"
                                    :translations="translations"
                                    :table="table"
                                    :unit="unit"
                                    :is-mobile="isMobile"></offer-filter-input>
            </div>
        </div>
    </div>
</template>

<script>
import OfferFilterInput from "@/components/OfferFilterInput";

export default {
    name: 'OfferFilter',
    components: {OfferFilterInput},
    created() {
        this.filtersModel = this.filters;
    },
    data() {
        return {
            filtersModel: null
        };
    },
    methods: {
        countSelectedFilters() {
            return Object.keys(this.files).length;
        }
    },
    props: ['label', 'options', 'filters', 'table', 'translations', 'unit', 'isMobile'],
}
</script>

<style scoped>
.btn.focus, .btn:focus, .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}
</style>
