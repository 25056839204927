<template>
    <offer-form v-if="offer"
                :allowed-image-types="allowedImageTypes"
                :max-file-size="maxFileSize"
                :offer="offer"
                :edit-mode="true"
                :save-handler="getHandleEditOffer"
                :locale="locale"></offer-form>
</template>

<script>
import OfferForm from "../OfferForm";
import OffersApi from "../../services/OffersApi";

export default {
    name: 'EditOffer',
    components: {OfferForm},
    data() {
        return {
            offer: null
        };
    },
    props: [
        'locale',
        'allowedImageTypes',
        'maxFileSize',
        'offerId',
        'csrfToken'
    ],
    created() {
        console.log(this.offerId);
        OffersApi.getEditOfferData(this.locale, this.offerId).then((response) => {
            this.offer = response.data;
        });
    },
    methods: {
        getHandleEditOffer(offerData) {
            return OffersApi.editOffer(offerData, this.locale, this.csrfToken).then(response => {
                return response.json();
            });
        }
    }
};
</script>

<style scoped>
</style>
