<template>
    <button id="loadingScreenModalToggle"
            type="button"
            class="d-none"
            data-toggle="modal"
            data-target="#loadingScreenModal">
    </button>
    <div id="loadingScreenModal"
         class="modal fade"
         tabindex="-1"
         data-backdrop="static"
         data-keyboard="false"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <button id="modalClose"
                            type="button"
                            class="d-none"
                            data-dismiss="modal"
                            aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="loading-screen-body">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoadingScreen',
    props: ['isLoading'],
    data() {
        return {
            display: false
        };
    },
    methods: {
        showModal() {
            const element = document.getElementById('loadingScreenModalToggle');
            if (element) {
                element.click();
            }
        },
        hideModal() {
            const element = document.getElementById('loadingScreenModal');
            if (element) {
                element.click();
            }
        }
    },
    watch: {
        isLoading(isLoading) {
            if (isLoading) {
                this.showModal();
            } else {
                this.hideModal();
            }
        }
    }
}
</script>

<style scoped>
.loading-screen-body {
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
}

.modal-content {
    background: none;
    border: none;
}
</style>
