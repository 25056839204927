<template>
    <button @click="toggle" :class="[btnClass]">
        <div class="d-flex align-items-center justify-content-center"
             v-if="(initialValue && isBookmarked == null) || isBookmarked">
            <i :class="[activeClass]"></i>
            {{ textActive }}
        </div>
        <div class="d-flex align-items-center justify-content-center" v-else>
            <i :class="[inactiveClass]"></i>
            {{ textInactive }}
        </div>
    </button>
</template>

<script>
export default {
    name: 'BookmarkToggle',
    data() {
        return {
            isBookmarked: null,
            isLoading: false
        }
    },
    props: ['initialValue', 'csrfToken', 'sessionId', 'offerId', 'activeClass', 'inactiveClass', 'btnClass', 'textActive', 'textInactive'],
    methods: {
        toggle() {
            if (!this.isLoading) {
                this.isLoading = true;

                if ((this.initialValue && this.isBookmarked === null) || this.isBookmarked) {
                    this.delete();

                } else {
                    this.add();
                }
            }
        },
        add() {
            fetch('/offer-bookmarks/add/' + this.offerId + '.json', {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': this.csrfToken,
                    'PHPSESSID': this.sessionId
                }
            }).then(res => {
                this.isLoading = false;

                if (res.ok) {
                    this.isBookmarked = true;
                }
            });
        },
        delete() {
            fetch('/offer-bookmarks/delete/' + this.offerId + '.json', {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': this.csrfToken,
                    'PHPSESSID': this.sessionId
                }
            }).then(res => {
                this.isLoading = false;

                if (res.ok) {
                    this.isBookmarked = false;
                }
            });
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
