<template>
    <div class='range-slider'>
        <input type="range" v-bind:min="min" v-bind:max="max" v-bind:step="step" v-model="sliderMin"
               @change="sliderMinChanged">
        <input type="range" v-bind:min="min" v-bind:max="max" v-bind:step="step" v-model="sliderMax"
               @change="sliderMaxChanged">
        <div class="w-100">
            <div class="d-flex justify-content-between w-100 mt-2">
                <div>
                    <label>
                        {{ min }} {{ unit }}
                    </label>
                </div>
                <div>
                    <label>
                        {{ max }} {{ unit }}
                    </label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col">
                    <input class="form-control" type="number" v-bind:min="min" v-bind:max="max"
                           v-bind:step="step" v-model="inputMin" @change="inputMinChanged">
                </div>
                <div class="col">
                    <input class="form-control" type="number" v-bind:min="min" v-bind:max="max"
                           v-bind:step="step" v-model="inputMax" @change="inputMaxChanged">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RangeSlider',
    data() {
        return {
            sliderMin: null,
            sliderMax: null,
            inputMin: null,
            inputMax: null
        };
    },
    props: ['min', 'max', 'count', 'selectedMin', 'selectedMax', 'unit', 'step'],
    created() {
        this.initValues();
    },
    methods: {
        initValues() {
            this.inputMin = this.selectedMin;
            this.inputMax = this.selectedMax;
            this.sliderMin = this.selectedMin ? this.selectedMin : this.min;
            this.sliderMax = this.selectedMax ? this.selectedMax : this.max;
        },
        inputMinChanged() {
            const min = parseFloat(this.min);
            let value = parseFloat(this.inputMin);

            if (value < this.min) {
                value = this.min;
                this.inputMin = value;
            } else if (this.selectedMax && value > this.selectedMax) {
                value = this.selectedMax;
                this.inputMin = value;
            } else if ((min + value) < parseFloat(this.step)) {
                value = min;
                this.inputMin = value;
            }

            this.emitMin(value);
            this.sliderMin = value;
            this.calculateCount();
        },
        inputMaxChanged() {
            const max = parseFloat(this.max);
            let value = parseFloat(this.inputMax);

            if (value > max) {
                value = max;
                this.inputMax = value;
            } else if (this.selectedMin && value < this.selectedMin) {
                value = this.selectedMin;
                this.inputMax = value;
            } else if ((max - value) < parseFloat(this.step)) {
                value = this.max;
                this.inputMax = value;
            }

            this.emitMax(value);
            this.sliderMax = value;
            this.calculateCount();
        },
        sliderMinChanged() {
            const min = parseFloat(this.sliderMin);
            let inputMin = min && min !== this.min ? min : null;

            if (inputMin && this.selectedMax && inputMin > this.selectedMax) {
                this.sliderMin = this.selectedMax;
                inputMin = this.selectedMax;
            }

            this.inputMin = inputMin;
            this.emitMin(inputMin);
            this.calculateCount();
        },
        sliderMaxChanged() {
            const max = parseFloat(this.sliderMax);
            let inputMax = max && max !== this.max ? max : null;

            if (inputMax && this.selectedMin && inputMax < this.selectedMin) {
                this.sliderMax = this.selectedMin;
                inputMax = this.selectedMin;
            }

            this.inputMax = inputMax;
            this.emitMax(inputMax);
            this.calculateCount();
        },
        calculateCount() {
            const count = this.filters && ((this.filters.selected.min !== null && parseFloat(this.filters.selected.min) !== this.min) || (this.filters.selected.max !== null && parseFloat(this.filters.selected.max) !== this.max)) ? 1 : 0;
            this.emitCount(count);
        },
        emitMin(value) {
            this.$emit('update:selectedMin', value);
        },
        emitMax(value) {
            this.$emit('update:selectedMax', value);
        },
        emitCount(value) {
            this.$emit('update:count', value);
        },
    },
    watch: {
        selectedMin() {
            this.initValues();
        },
        selectedMax() {
            this.initValues();
        }
    }
};
</script>

<style scoped>
.range-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    text-align: center;
    position: relative;
}

input[type=range] {
    position: absolute;
    left: 0;
    top: 0;
}

input[type=range] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-ms-fill-lower {
    background: #2497e3;
}

input[type=range]:focus::-ms-fill-upper {
    background: #2497e3;
}

input[type=range]::-moz-range-track {
    z-index: 1;
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #2497e3;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type=range]::-moz-range-thumb {
    z-index: 2 !important;
    position: relative;
    box-shadow: 0 0 0 #000;
    border: 1px solid #2497e3;
    height: 60px;
    /*height: 18px;*/
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
    -moz-appearance: none;
    margin-top: -7px;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #2497e3;
    border-radius: 1px;
    box-shadow: none;
    border: 0;
}

input[type=range]::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    box-shadow: 0 0 0 #000;
    border: 1px solid #2497e3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
}
</style>
