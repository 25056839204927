<template>
    <div class="position-relative">
        <input class="position-absolute form-control" style="z-index: -1"
               name="address" required v-model="selected"
               @focusin="setFocus" v-bind:id="inputId">
        <Autocomplete
            @input="inputChanged($event)"
            @onSelect="select($event)"
            :results="items"
            :input-class="['form-control', 'address-typeahead-input']"
            :debounce="500"
            :max="5"
            :results-container-class="['card', 'suggestion-container']"
            :results-item-class="['dropdown-item',  'cursor-pointer', 'suggestion']"
            display-field="label"
            :initial-value="initialValue"
        ></Autocomplete>
    </div>
</template>

<script>
import Autocomplete from "@/components/Autocomplete";

export default {
    name: 'AddressSelection',
    components: {Autocomplete},
    data() {
        return {
            selected: null,
            items: []
        };
    },
    props: ['locale', 'csrfToken', 'inputId', 'initialValue', 'initialFeatureId'],
    created() {
        if (this.initialFeatureId) {
            this.selected = this.initialFeatureId;
        }
    },
    methods: {
        setFocus() {
            this.jQuery('input.address-typeahead-input').focus();
        },
        inputChanged(value) {
            this.selected = null;

            if (value && value.length >= 3) {
                this.searchAddress(value);
            } else {
                this.items = [];
            }
        },
        select(value) {
            this.selected = JSON.stringify(value);
        },
        searchAddress(searchTerm) {
            fetch('/' + this.locale + '/address-selection/search.json', {
                body: JSON.stringify({searchTerm}),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': this.csrfToken,
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(body => {
                        if (body.success) {
                            this.items = body.data;
                        } else {
                            this.items = [];
                        }
                    });
                }
            });
        }
    }
}
</script>

<style scoped></style>
