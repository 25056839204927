<template>
    <a :class="classes" @click="click">{{ content }}</a>
</template>

<script>
export default {
    name: 'ConsentSettingsLink',
    data() {
        return {};
    },
    props: ['classes', 'content'],
    methods: {
        click() {
            // @ts-ignore
            this.klaro.show();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
