<template>
    <div class='range-slider'>
        <div :class="{'slider-mobile': isMobile}">
            <div v-bind:id="sliderId"></div>
        </div>
        <div class="d-flex justify-content-between mt-2">
            <input v-bind:id="input0Id" class="form-control mr-2" type="number" @change="inputMinChanged($event)">
            <input v-bind:id="input1Id" class="form-control ml-2" type="number" @change="inputMaxChanged($event)">
        </div>
    </div>
</template>

<script>

import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default {
    name: 'RangeSlider',
    data() {
        return {
            sliderMin: null,
            sliderMax: null,
            sliderId: Math.random().toString(),
            input0Id: Math.random().toString(),
            input1Id: Math.random().toString(),
            slider: null,
            selectedValueInitialized: 0,
            creationTime: Date.now()
        };
    },
    props: ['min', 'max', 'count', 'selectedMin', 'selectedMax', 'unit', 'step', 'isMobile'],
    created() {
        this.initValues();
    },
    mounted() {
        this.initSlider();
    },
    methods: {
        initValues() {
            this.sliderMin = parseFloat(this.selectedMin ? this.selectedMin : this.min);
            this.sliderMax = parseFloat(this.selectedMax ? this.selectedMax : this.max);
        },
        initSlider() {
            const slider = document.getElementById(this.sliderId);
            const input0 = document.getElementById(this.input0Id);
            const input1 = document.getElementById(this.input1Id);
            const inputs = [input0, input1];

            noUiSlider.create(slider, {
                start: [this.sliderMin, this.sliderMax],
                connect: true,
                range: {
                    min: parseFloat(this.min),
                    max: parseFloat(this.max)
                }
            });

            slider.noUiSlider.on('update', (values, handle) => {
                inputs[handle].value = values[handle];
                this.emitUpdate(parseFloat(values[0]), parseFloat(values[1]));
            });

            this.slider = slider;
        },
        emitUpdate(min, max) {
            let selectedMin = min === null || min === this.min ? null : parseFloat(min);
            if (selectedMin <= this.min) {
                selectedMin = null;
            }

            let selectedMax = max === null || max === this.max ? null : parseFloat(max);
            if (selectedMax >= this.max) {
                selectedMax = null;
            }

            const count = (min === null || min === this.min) && (max === null || max === this.max) ? 0 : 1;

            this.$emit('update:selectedMin', selectedMin);
            this.$emit('update:selectedMax', selectedMax);
            this.$emit('update:count', count);
        },
        inputMinChanged(value) {
            this.slider.noUiSlider.set([value.target.value, this.slider.noUiSlider.get(1)]);
        },
        inputMaxChanged(value) {
            this.slider.noUiSlider.set([this.slider.noUiSlider.get(0), value.target.value]);
        }
    },
    watch: {
        selectedMin() {
            if (this.selectedValueInitialized < 2) {
                if (this.creationTime > (Date.now() - 1000)) {
                    this.slider.noUiSlider.set([this.selectedMin, this.slider.noUiSlider.get(1)]);
                }
                this.selectedValueInitialized++;
            }
        },
        selectedMax() {
            if (this.selectedValueInitialized < 2) {
                if (this.creationTime > (Date.now() - 1000)) {
                    this.slider.noUiSlider.set([this.slider.noUiSlider.get(0), this.selectedMax]);
                }
                this.selectedValueInitialized++;
            }
        },
        min(minValue) {
            this.sliderMin = minValue;
        },
        max(maxValue) {
            this.sliderMax = maxValue;
        },
        sliderMin(nextMin, oldMin) {
            if (this.slider) {
                const selectedMin = parseFloat(this.slider.noUiSlider.get(0)[0]);

                this.slider.noUiSlider.updateOptions({
                    range: {
                        'min': nextMin,
                        'max': this.sliderMax
                    }
                });

                if (selectedMin < nextMin || oldMin === selectedMin) {
                    this.slider.noUiSlider.set([nextMin, this.slider.noUiSlider.get(1)]);
                }
            }
        }
    },
    sliderMax(nextMax, oldMax) {
        if (this.slider) {
            const selectedMax = parseFloat(this.slider.noUiSlider.get(0)[1]);

            this.slider.noUiSlider.updateOptions({
                range: {
                    'min': this.sliderMin,
                    'max': nextMax
                }
            });

            if (selectedMax > nextMax || oldMax === selectedMax) {
                this.slider.noUiSlider.set([this.slider.noUiSlider.get(0), nextMax]);
            }
        }
    }
};
</script>

<style scoped>
.slider-mobile {
    margin: 0 8px;
}

.range-slider {
    width: 100%;
}
</style>
