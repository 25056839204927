<template>
    <div class="d-none"></div>
</template>

<script>
import Vue from 'vue';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';

export default {
    name: 'Sentry',
    props: {
        dsn: {
            type: String,
            default: ''
        }
    },
    data() {
        return {};
    },
    mounted() {
        Raven
            .config(this.dsn)
            .addPlugin(RavenVue, Vue)
            .install();
    }
}

</script>
<style scoped></style>
