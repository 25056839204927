<template>
    <Autocomplete
        @input="inputChanged($event)"
        @onSelect="select($event)"
        :results="productSuggestions"
        :input-class="['form-control', 'address-typeahead-input']"
        :debounce="500"
        :max="7"
        :results-container-class="['card', 'suggestion-container']"
        :results-item-class="['dropdown-item',  'cursor-pointer', 'suggestion', 'text-inline-ellipses', 'small']"
        :placeholder="placeholder"
        :initial-value="initialValue">
        <div class="input-group-append">
            <button type="button"
                    class="btn btn-outline-secondary"
                    :class="{'disabled': !initialValue && !selectedProduct}"
                    @click="resetProduct()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </Autocomplete>
</template>

<script>
import Autocomplete from "@/components/Autocomplete";

export default {
    name: 'ProductAutocomplete',
    components: {Autocomplete},
    data() {
        return {
            selectedProduct: null,
            productSuggestions: [],
        };
    },
    props: ['csrfToken', 'locale', 'placeholder', 'initialValue'],
    emits: ['selectProduct'],
    created() {
    },
    methods: {
        inputChanged(productName) {
            this.selectedProduct = null;

            if (productName && productName.length >= 3) {
                this.loadSuggestions(productName);
            } else {
                this.productSuggestions = [];
            }
        },
        select(product) {
            this.selectedProduct = product;
            this.$emit('selectProduct', this.selectedProduct);
        },
        loadSuggestions(productName) {
            return fetch('/' + this.locale + '/products/find-by-name.json', {
                method: 'POST',
                body: JSON.stringify({'name': productName}),
                headers: {
                    'X-CSRF-Token': this.csrfToken,
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        if (body.success) {
                            this.productSuggestions = body.data;
                        }
                    });
                }
            });
        },
        resetProduct() {
            this.selectedProduct = null;
            this.$emit('selectProduct', null);
        },
    }
}
</script>

<style scoped>
</style>
