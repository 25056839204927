<template>
    <div v-if="isClosed">
        <span class="badge badge-dark">
            {{endetTranslation}}
        </span>
    </div>
    <div v-else :class="[countdownClass]">
        {{displayValue}}
    </div>
</template>

<script>
export default {
    name: 'OfferCountdown',
    data() {
        return {
            queryBidsIntervalInSeconds: 10,
            timer: null,
            displayValue: '',
            latestBidsRequestTimestamp: Date.now(),
            plannedEndDate: null,
            isLoading: false,
            countdownClass: '',
            isClosed: false,
            translations: {
                'de': {
                    timeDays: 'Noch ${days}T ${hours} Std (${date})',
                    timeHours: 'Noch ${hours} Std ${minutes} Min (${date})',
                    timeMinutes: 'Noch ${minutes} Min (${date})',
                    timeCountdown: 'Noch ${minutes} Min ${seconds} Sek',
                },
                'en': {
                    timeDays: '${days}d ${hours}h left (${date})',
                    timeHours: '${hours}h ${minutes}m left (${date})',
                    timeMinutes: '${minutes}m left (${date})',
                    timeCountdown: '${minutes}m ${seconds}s left',
                },
                'it': {
                    timeDays: '${days}g e ${hours}h rimanenti (${date})',
                    timeHours: '${hours}h e ${minutes} min. rimanenti (${date})',
                    timeMinutes: '${minutes} min. rimanenti (${date})',
                    timeCountdown: '${minutes} min. e ${seconds} s. rimanenti',
                },
                'fr': {
                    timeDays: '${days} j. et ${hours} h restantes (${date})',
                    timeHours: 'Il reste ${hours} h et ${minutes} min (${date})',
                    timeMinutes: '${minutes} min restantes (${date})',
                    timeCountdown: 'Il reste ${minutes} min et ${seconds} s',
                }
            }
        }
    },
    created() {
        this.init();
    },
    unmounted() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    props: ['plannedOfferEndDate', 'isOfferClosed', 'locale', 'offerId', 'updateBids', 'startCountdownBeforeEndInSeconds'],
    methods: {
        init() {
            this.isClosed = this.isOfferClosed;
            this.plannedEndDate = this.plannedOfferEndDate;

            this.handleDate();

            // start timer
            if (!this.isClosed) {
                this.timer = setInterval(() => this.handleDate(), 1000);
            }
        },
        handleDate() {
            // stop timer if offer is closed
            if (this.timer && this.isClosed) {
                clearInterval(this.timer)
                this.timer = null;
                return;
            }

            // calculate time interval until end of offer
            const diffInSeconds = this.calculateSecondsUntilPlannedEnd();

            // calculate countdown value
            this.displayValue = this.calculateCountdownValue(diffInSeconds);

            // Check if a new bid has been created
            if (this.updateBids && !this.isClosed) {
                this.queryBids(diffInSeconds);
            }
        },
        isSameDay(diffInSeconds) {
            const currentDate = new Date();
            const endDate = new Date(Date.now() + diffInSeconds * 1000);
            return currentDate.getMonth() === endDate.getMonth()
                && currentDate.getDate() === endDate.getDate()
                && currentDate.getFullYear() === endDate.getFullYear();
        },
        formatCountdownValue(days, hours, minutes, seconds, isCountdown, diffInSeconds) {
            // load translation string
            let displayValue = '';
            if (isCountdown) {
                displayValue = this.translations[this.locale].timeCountdown
            } else if (days) {
                displayValue = this.translations[this.locale].timeDays;
            } else if (hours) {
                displayValue = this.translations[this.locale].timeHours;
            } else {
                displayValue = this.translations[this.locale].timeMinutes;
            }

            // replace placeholders
            displayValue = displayValue.replace('${days}', days);
            displayValue = displayValue.replace('${hours}', hours);
            displayValue = displayValue.replace('${minutes}', minutes);
            displayValue = displayValue.replace('${seconds}', seconds);

            // unset date if same day
            if (this.isSameDay(diffInSeconds)) {
                displayValue = displayValue.replace(' (${date})', '');

                // set date if not same day
            } else {
                displayValue = displayValue.replace('${date}', new Date(this.plannedEndDate * 1000).toLocaleString(this.locale, {
                    month: '2-digit',
                    year: 'numeric',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                }));
            }

            return displayValue;
        },
        queryBids() {
            if (this.isLoading
                || (this.latestBidsRequestTimestamp + this.queryBidsIntervalInSeconds * 1000) > Date.now()) {
                return;
            }

            this.true = false;
            this.latestBidsRequestTimestamp = Date.now();

            fetch('/offers/get-countdown-info/' + this.offerId + '.json', {
                method: 'GET',
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        this.plannedEndDate = body.plannedEnd ?? this.plannedEndDate;
                        this.isClosed = body.isClosed;

                        if (this.isClosed && this.timer) {
                            clearInterval(this.timer);
                            this.timer = null;
                            window.location.reload();
                        }
                    });
                }
                this.isLoading = false;
            });
        },
        calculateCountdownValue(diffInSeconds) {
            const days = parseInt(diffInSeconds / 60 / 60 / 24)
            const hours = parseInt((diffInSeconds - days * 24 * 60 * 60) / 60 / 60);
            const minutes = parseInt((diffInSeconds - days * 24 * 60 * 60 - hours * 60 * 60) / 60)
            const seconds = diffInSeconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

            // show diff in minutes and seconds
            if (diffInSeconds <= this.startCountdownBeforeEndInSeconds) {
                this.countdownClass = 'text-danger';
                return this.formatCountdownValue(days, hours, minutes, seconds, true, diffInSeconds);

                // show diff as date
            } else {
                return this.formatCountdownValue(days, hours, minutes, seconds, false, diffInSeconds);
            }
        },
        calculateSecondsUntilPlannedEnd() {
            const currentTimestamp = parseInt(Date.now() / 1000);
            const diff = parseInt(this.plannedEndDate) - currentTimestamp;
            return diff > 0 ? diff : 0;
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
