<template>
    <div v-if="filters.type === 'multi-select'">
        <div class="form-check" v-for="option in options" :key="option.id">
            <input v-bind:id="table + option.id" type="checkbox" class="form-check-input"
                   v-model="filterModel.selected[option.id]" @change="multiSelectChanged">
            <label v-bind:for="table + option.id" class="form-check-label">
                {{ option.name }}
            </label>
        </div>
    </div>
    <div v-else-if="filters.type === 'dropdown'">
        <select class="form-control" v-model="filterModel.selected" @change="dropdownChanged">
            <option :value="null">
                {{ translations.ALL }}
            </option>
            <option v-for="option in options" :value="option.id" :key="option.id">
                {{ option.name }}
            </option>
        </select>
    </div>
    <div v-else-if="filters.type === 'range'">
        <double-slider :min="options.min"
                      :max="options.max"
                      :unit="unit"
                      :step="filters.step"
                       :is-mobile="isMobile"
                      v-model:selected-min="filterModel.selected.min"
                      v-model:selected-max="filterModel.selected.max"
                      v-model:count="filterModel.count"></double-slider>
    </div>
</template>

<script>
import DoubleSlider from "@/components/DoubleSlider";

export default {
    name: 'OfferFilterInput',
    components: {DoubleSlider},
    data() {
        return {
            filterModel: null
        };
    },
    props: ['label', 'options', 'filters', 'table', 'translations', 'unit', 'isMobile'],
    emits: ['update:filters'],
    created() {
        if (this.type === 'dropdown') {
            this.initDropdown();
        }
        this.filterModel = this.filters;
    },
    methods: {
        initDropdown() {
            this.dropdownChanged();
        },
        initMultiSelect() {
            this.multiSelectChanged();
        },
        multiSelectChanged() {
            let count = 0;
            Object.keys(this.filters.selected).forEach(id => {
                if (this.filters.selected[id]) {
                    count++;
                }
            });
            this.filterModel.count = count;
            this.$emit('update:filters', this.filterModel);
        },
        dropdownChanged() {
            this.filterModel.count = this.filters.selected ? 1 : 0;
            this.$emit('update:filters', this.filterModel);
        }
    },
}
</script>

<style scoped></style>
