import {createApp} from 'vue/dist/vue.esm-bundler'
import App from './App.vue'

const app = createApp(App);

/* global klaro */
app.config.globalProperties.klaro = klaro;
/* global jQuery */
app.config.globalProperties.jQuery = jQuery;
/* global intlTelInput */
app.config.globalProperties.intlTelInput = intlTelInput;


/* directives */
app.directive('tooltip',
    function (el, binding) {
        /*global $*/
        $(el).tooltip({
            title: binding.value,
            placement: binding.arg,
            trigger: 'hover'
        })
    })

app.mount('#vue-app');
