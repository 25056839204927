<template>
    <slot :isShowAll="isShowAll" :toggle="toggle"></slot>
</template>

<script>
export default {
    name: 'QuestionsToggle',
    data() {
        return {
            isShowAll: false
        }
    },
    methods: {
        toggle() {
            this.isShowAll = !this.isShowAll;
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
