<template>
    <div class="sidebar-in">
        <div class="sidebar-toggle-out" @click="toggleMenu">
            <slot name="sidebar-toggle-out"></slot>
        </div>
        <slot name="sidebar-in-menu"></slot>
    </div>
    <div class="sidebar-out">
        <div class="sidebar-out-wrapper">
            <div class="sidebar-out-inner">
                <slot name="sidebar-out"></slot>
            </div>
            <div class="sidebar-toggle-in" @click="toggleMenu" :class="{'toggle-hidden': isCollapsed}">
                <i class="fa fa-times"></i>
            </div>
        </div>
    </div>
    <div class="sidebar-opacity" v-if="!isCollapsed"></div>
</template>

<script>
export default {
    name: 'Sidebar',
    props: [],
    data() {
        return {
            isCollapsed: true
        };
    },
    methods: {
        toggleMenu() {
            const sidebar = this.getSidebarElement();

            if (sidebar) {
                if (this.isCollapsed) {
                    sidebar.style.width = '100%';
                    this.isCollapsed = false;
                } else {
                    sidebar.style.width = '0';
                    this.isCollapsed = true;
                }
            }
        },
        getSidebarElement() {
            return document.getElementsByClassName('sidebar-out')?.item(0);
        }
    }
}
</script>

<style scoped>

.sidebar-in {
    display: flex;
    align-items: center;
    width: 100%;
}

.sidebar-opacity {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.25s;
    z-index: 100;
}

.sidebar-toggle-out {
    font-size: 1.5rem;
    padding: 0.75rem 0.75rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.toggle-hidden svg{
    font-size: 0 !important;
}

.sidebar-out {
    width: 0;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 101;
    transition: 0.5s;
}

.sidebar-out-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.sidebar-toggle-in {
    margin-top: 2rem;
    text-align: right;
    font-size: 2rem;
    padding: 1rem;
    min-width: 3rem;
}

.sidebar-out-inner {
    background-color: white;
    flex-grow: 1;
}

</style>
