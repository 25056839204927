<template>
    <div>
        <input v-bind:id="inputId"
               v-model="phone"
               :required="required ? true : null"
               class="data-input"
               tabindex="-1"
               v-bind:name="inputId">
        <input id="intl-input"
               class="form-control"
               v-bind:class="{ 'is-invalid': isInvalid }"
               type="tel"
               @change="change($event)">
    </div>
</template>
<script>

export default {
    name: 'PhoneNumberInput',
    props: ['required', 'inputId', 'initialValue'],
    data() {
        return {
            phoneInput: null,
            phone: null,
            isInvalid: false,
            isTouched: false
        }
    },
    methods: {
        change() {
            const isValid = this.phoneInput.isValidNumber();
            const number = this.phoneInput.getNumber();

            this.phone = isValid ? number : null;
            this.isTouched = this.isTouched || !!number;

            this.validate(this.phone, isValid)
        },
        validate(value, isValid) {
            this.isInvalid = this.isTouched && ((this.required && !value) || (value && !isValid))
        }
    },
    mounted() {
        const input = document.querySelector('#intl-input')

        this.phoneInput = this.intlTelInput(input, {
            preferredCountries: ['ch', 'de', 'fr', 'it', 'at'],
            autoPlaceholder: 'aggressive',
            utilsScript: 'js/intl-tel-input-utils.js',
            customContainer: 'w-100'
        });

        input.addEventListener('countrychange', () => this.change());

        if (this.initialValue) {
            this.phone = this.initialValue;
            this.phoneInput.setNumber(this.initialValue);
        }
    }
}
</script>

<style scoped>
.data-input {
    margin-top: 16px;
    z-index: -1;
    position: absolute;
}
</style>
