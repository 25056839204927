<template>
    <div v-if="options && options.data">
        <div v-if="options.data.isUserOffer" class="card-body">
            <div class="alert alert-info">
                {{ options.translations.YOUR_OFFER }}
            </div>
            <div v-if="options.data.isAuction" class="d-flex justify-content-between">
                <div>
                    <div>
                        {{ options.translations.BIDS }}
                    </div>
                    <div>
                        {{ options.translations.CURRENT_PRICE }}
                    </div>
                </div>
                <div>
                    <div>
                        {{ options.data.bidsCount }}
                    </div>
                    <div>
                        {{ options.translations.PRICE }}
                    </div>
                </div>
            </div>
            <div v-if="options.data.maxPrice && !options.isOfferClosed" class="d-flex justify-content-between">
                <div>
                    {{ options.translations.IMMEDIATE_PURCHASE_PRICE }}
                </div>
                <div>
                    {{ options.translations.MAX_PRICE }}
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="options.data.isAuction" class="card-body">
                <div v-if="options.data.isHighestBidder">
                        <span class="badge badge-success">
                            {{ options.translations.YOU_ARE_THE_HIGHEST_BIDDER }}
                        </span>
                    <div class="d-flex justify-content-between">
                        <div>
                            <div>
                                {{ options.translations.CURRENT_BID }}
                            </div>
                            <div>
                                {{ options.translations.YOUR_MAX_PRICE }}
                            </div>
                        </div>
                        <div>
                            <div>
                                {{ options.translations.PRICE }}
                            </div>
                            <div>
                                {{ options.translations.MAX_BID }}
                            </div>
                        </div>
                    </div>
                    <div v-if="this.increaseBid.responseMessage">
                        <div class="mt-3 alert"
                             :class="this.increaseBid.isResponseError ? 'alert-danger' : 'alert-success'">
                            {{ this.increaseBid.responseMessage }}
                        </div>
                    </div>
                    <div class="mt-3 bid-form" v-if="!options.isOfferClosed">
                        <div class="form-group">
                            <label for="increaseBid">
                                {{ options.translations.INCREASE_BID_LABEL }}
                            </label>
                            <input id="increaseBid" class="form-control" v-model="increaseBid.value"
                                   type="number" step="1" @change="validateIncreaseBid"
                                   :placeholder="options.translations.YOUR_NEW_MAX_BID"
                                   :readonly="this.increaseBid.isCreated"
                                   :class="increaseBid.isError && increaseBid.isTouched ? 'is-invalid' : ''">
                            <div class="invalid-feedback">
                                {{ options.errorMessages.INCREASE_BID }}
                            </div>
                            <button class="btn btn-secondary w-100 mt-2" @click="startIncreaseBid"
                                    v-if="!this.increaseBid.isCreated">
                                {{ options.translations.INCREASE_MAXIMUM_BID }}
                            </button>
                            <div v-else>
                                <button class="btn bg-gold w-100 mt-2" @click="executeIncreaseBid">
                                    {{ options.translations.PLACE_BID_WITH_COSTS }}
                                </button>
                                <button class="btn bg-danger text-white w-100 mt-2" @click="cancelIncreaseBid">
                                    {{ options.translations.CANCEL }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="options.data.isUserBid && !options.data.isHighestBidder" class="alert alert-warning">
                        {{ options.translations.OUTBID }}
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            {{
                                options.data.bidsCount === 0 ? options.translations.START_PRICE : options.translations.CURRENT_BID
                            }}
                        </div>
                        <div>
                            {{ options.translations.PRICE }}
                        </div>
                    </div>
                    <div class="mt-3 bid-form" v-if="!options.isOfferClosed">
                        <div class="mt-3">
                            <div class="form-group">
                                <label for="placeBid">
                                    {{ options.translations.PLACE_BID_LABEL }}
                                </label>
                                <input id="placeBid" class="form-control" v-model="placeBid.value"
                                       type="number" step="1" @change="validatePlaceBid"
                                       :placeholder="options.translations.YOUR_MAXIMUM_BID"
                                       :readonly="this.placeBid.isCreated"
                                       :class="placeBid.isError && placeBid.isTouched ? 'is-invalid' : ''">
                                <div class="invalid-feedback">
                                    {{ options.errorMessages.PLACE_BID }}
                                </div>
                                <div v-if="this.placeBid.isCreated">
                                    <button class="btn bg-gold w-100 mt-2" @click="executePlaceBid">
                                        {{ options.translations.PLACE_BID_WITH_COSTS }}
                                    </button>
                                    <button class="btn bg-danger text-white w-100 mt-2" @click="cancelPlaceBid">
                                        {{ options.translations.CANCEL }}
                                    </button>
                                </div>
                                <button v-else class="btn btn-secondary w-100 mt-2" @click="startPlaceBid">
                                    {{ options.translations.SUBMIT_BID }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="options.data.maxPrice && !options.isOfferClosed" class="card-body border-top">
                <div class="d-flex justify-content-between">
                    <div>
                        {{ options.translations.IMMEDIATE_PURCHASE_PRICE }}
                    </div>
                    <div>
                        {{ options.translations.MAX_PRICE }}
                    </div>
                </div>
                <div class="buy mt-2">
                    <div v-if="isBuyNowCreated">
                        <button class="btn bg-gold w-100" @click="executeBuyNow">
                            {{ options.translations.BUY_NOW_WITH_COSTS }}
                        </button>
                        <button class="btn btn-danger w-100 mt-2 text-white" @click="cancelBuyNow">
                            {{ options.translations.CANCEL }}
                        </button>
                    </div>
                    <div v-else>
                        <button class="btn btn-secondary w-100" @click="startBuyNow">
                            {{ options.translations.BUY_NOW }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OfferBids',
    data() {
        return {
            options: null,
            timer: null,
            isLoading: false,
            increaseBid: {
                value: null,
                isError: true,
                isTouched: false,
                created: false,
                isResponseError: false,
                responseMessage: null
            },
            placeBid: {
                value: null,
                isError: true,
                isTouched: false,
                created: false,
                isResponseError: false,
                responseMessage: null
            },
            isBuyNowCreated: false
        };
    },
    props: ['offerId', 'csrfToken', 'locale', 'hasToVerifyPhoneNumber', 'hasToUploadIdentityDocuments', 'mayPlaceBids'],
    created() {
        // start timer
        if (!this.isClosed) {
            this.timer = setInterval(() => this.loadOptions(), 5000);
        }

        this.loadOptions();

    },
    unmounted() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        loadOptions() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            fetch('/' + this.locale + '/offers/load-bid-options/' + this.offerId + '.json', {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': this.csrfToken,
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        this.options = body;

                        if (body.data.isOfferClosed && this.timer) {
                            clearInterval(this.timer);
                        }

                        this.isLoading = false;
                    });
                }
            });
        },
        validateIncreaseBid() {
            this.increaseBid.isTouched = true;
            this.increaseBid.isError = !(this.increaseBid.value > this.options.data.userMaxBid && (!this.options.data.maxPrice || this.increaseBid.value <= this.options.data.maxPrice));
        },
        startIncreaseBid() {
            this.redirectOnMissingInformation();

            if (!this.options.data.isUser) {
                this.redirectToLogin();
                return;
            }

            if (!this.increaseBid.isError && this.increaseBid.isTouched) {
                this.increaseBid.isCreated = true;
            }

            this.increaseBid.isTouched = true;
            this.increaseBid.isResponseError = false;
            this.increaseBid.responseMessage = null;
        },
        executeIncreaseBid() {
            this.redirectOnMissingInformation();

            if (this.increaseBid.isCreated) {
                fetch('/' + this.locale + '/bids/increase-bid/' + this.offerId + '.json', {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': this.csrfToken,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        amount: this.increaseBid.value
                    })
                }).then(res => {
                    if (res.ok) {
                        this.cancelIncreaseBid();
                        res.json().then(body => {
                            this.increaseBid.isResponseError = !body.success;
                            this.increaseBid.responseMessage = body.message;
                            this.loadOptions();
                        });
                    } else {
                        window.location.reload();
                    }
                });
            }
        },
        cancelIncreaseBid() {
            this.increaseBid.isCreated = false;
            this.increaseBid.value = null;
            this.increaseBid.isTouched = false;
        },
        startBuyNow() {
            this.redirectOnMissingInformation();

            if (!this.options.data.isUser) {
                this.redirectToLogin();
                return;
            } else {
                this.isBuyNowCreated = true;
            }
        },
        cancelBuyNow() {
            this.isBuyNowCreated = false;
        },
        executeBuyNow() {
            this.redirectOnMissingInformation();

            if (this.isBuyNowCreated) {
                fetch('/' + this.locale + '/bids/buy/' + this.offerId + '.json', {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': this.csrfToken,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({offerId: this.offerId})
                }).then((res) => {
                    if (res.redirected) {
                        window.location = res.url;
                    } else {
                        window.location.reload();
                    }
                });
            }
        },
        startPlaceBid() {
            this.redirectOnMissingInformation();

            if (!this.options.data.isUser) {
                this.redirectToLogin();
                return;
            }

            if (!this.placeBid.isError) {
                this.placeBid.isCreated = true;
            }
            this.placeBid.isTouched = true;
            this.placeBid.isResponseError = false;
            this.placeBid.responseMessage = null;
        },
        cancelPlaceBid() {
            this.placeBid.isCreated = false;
            this.placeBid.isTouched = false;
            this.placeBid.value = null;
        },
        executePlaceBid() {
            this.redirectOnMissingInformation();
            if (this.placeBid.isCreated) {
                fetch('/' + this.locale + '/bids/place-bid/' + this.offerId + '.json', {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': this.csrfToken,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({amount: this.placeBid.value})
                }).then(res => {
                    if (res.ok) {
                        this.cancelIncreaseBid();
                        res.json().then(body => {
                            this.placeBid.isResponseError = !body.success;
                            this.placeBid.responseMessage = body.message;
                            this.loadOptions();
                        });
                    } else {
                        window.location.reload();
                    }
                });
            }
        },
        validatePlaceBid() {
            this.placeBid.isTouched = true;
            this.placeBid.isError = !this.placeBid.value || this.placeBid.value < this.options.data.minBidPrice;
        },
        redirectToLogin() {
            window.location.href = encodeURI('/' + this.locale + '/auth/login?redirect=/' + this.locale + '/offers/view/' + this.offerId);
        },
        resetForms() {
            this.isBuyNowCreated = false;

            this.cancelPlaceBid();
            this.cancelIncreaseBid();
        },
        redirectOnMissingInformation() {
            if (this.hasToUploadIdentityDocuments || !this.mayPlaceBids) {
                window.location.href = encodeURI('/' + this.locale + '/users-security/upload-identity-documents');
            } else if (this.hasToVerifyPhoneNumber) {
                window.location.href = encodeURI('/' + this.locale + '/users-security/verify-phone?origin=bids');
            }
        }
    },
    computed: {
        bidsCount() {
            return this.options?.data?.bidsCount;
        }
    },
    watch: {
        bidsCount() {
            this.resetForms();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
