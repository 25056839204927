<template>
    <div class="form-group">
        <label for="metals">
            {{translations?.form?.METAL}}
        </label>
        <select id="metals" class="form-control" v-model="metalId" @change="loadOptions">
            <option :value="null">{{translations?.form?.ALL}}</option>
            <option :value="value" :key="key" v-for="(key, value) in options.metals">{{key}}</option>
        </select>
    </div>
    <div class="form-group">
        <label for="forms">
            {{translations?.form?.FORM}}
        </label>
        <select id="forms" class="form-control" v-model="formId" @change="loadOptions">
            <option :value="null">{{translations?.form?.ALL}}</option>
            <option :value="form.id" :key="form" v-for="form in options.forms">{{form.form}}</option>
        </select>
    </div>
    <div class="form-group" v-if="showCountriesFilter">
        <label for="securityFeatures">
            {{translations?.form?.COUNTRY}}
        </label>
        <select id="countries" class="form-control" v-model="countryId" @change="loadOptions">
            <option :value="null">{{translations?.form?.ALL}}</option>
            <option :value="value" :key="key" v-for="(key, value) in options.countries">{{key}}</option>
        </select>
    </div>
    <div class="form-group">
        <label for="weights">
            {{translations?.form?.WEIGHT}}
        </label>
        <select id="weights" class="form-control" v-model="weightId" @change="loadOptions">
            <option :value="null">{{translations?.form?.ALL}}</option>
            <option :value="value" :key="key" v-for="(key, value) in options.weights">{{key}}</option>
        </select>
    </div>
    <div class="form-group">
        <label for="finenesses">
            {{translations?.form?.FINENESS}}
        </label>
        <select id="finenesses" class="form-control" v-model="finenessId" @change="loadOptions">
            <option :value="null">{{translations?.form?.ALL}}</option>
            <option :value="value" :key="key" v-for="(key, value) in options.finenesses">{{key}}</option>
        </select>
    </div>
    <div class="form-group">
        <label for="securityFeatures">
            {{translations?.form?.SECURITY_FEATURE}}
        </label>
        <select id="securityFeatures" class="form-control" v-model="securityFeatureId" @change="loadOptions">
            <option :value="null">{{translations?.form?.ALL}}</option>
            <option :value="value" :key="key" v-for="(key, value) in options.securityFeatures">{{key}}</option>
        </select>
    </div>
    <div class="form-group">
        <label for="products">
            {{translations?.form?.PRODUCT}}
        </label>
        <select id="products" class="form-control" v-model="productId" @change="validateProductId(true)"
                v-bind:class="{'is-invalid': validation.productId.isInvalid && validation.productId.isTouched}">
            <option :value="value" :key="key" v-for="(key, value) in options.products">{{key}}</option>
        </select>
        <div class="invalid-feedback">
            {{translations?.errorMessage?.PRODUCT}}
        </div>
    </div>
    <div class="row">
        <div class="col">
            <button class="btn btn-light w-100" @click="reset">
                {{translations?.form?.RESET}}
            </button>
        </div>
        <div class="col">
            <button class="btn btn-secondary w-100" @click="save">
                {{translations?.form?.CONTINUE}}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OfferSelectProductForm',
    data() {
        return {
            translations: {},
            options: {
                metals: [],
                finenesses: [],
                weights: [],
                products: [],
                securityFeatures: [],
                forms: [],
                countries: []
            },
            metalId: null,
            finenessId: null,
            formId: null,
            weightId: null,
            securityFeatureId: null,
            productId: null,
            countryId: null,
            validation: {productId: {isTouched: false, isInvalid: true}},
            showCountriesFilter: false
        };
    },
    props: ['csrfToken', 'sessionId', 'locale'],
    created() {
        this.loadTranslations();
        this.loadOptions();
    },
    methods: {
        loadTranslations() {
            fetch('/' + this.locale + '/offers/select-product-translations.json', {
                method: 'GET',
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        this.translations = body.translations;
                    });
                }
            });
        },
        loadOptions() {
            this.updateCountriesFilter();

            fetch('/' + this.locale + '/offers/product-options.json', {
                method: 'POST',
                body: JSON.stringify({
                    metalId: this.metalId,
                    finenessId: this.finenessId,
                    formId: this.formId,
                    weightId: this.weightId,
                    securityFeatureId: this.securityFeatureId,
                    countryId: this.countryId,
                }),
                headers: {
                    'X-CSRF-Token': this.csrfToken,
                    'PHPSESSID': this.sessionId,
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        this.options = body;
                        this.validateProductId(false);
                    });
                }
            });
        },
        validateProductId(isTouched = true) {
            this.validation.productId.isTouched = isTouched ? isTouched : this.validation.productId.isTouched;
            this.validation.productId.isInvalid = !this.productId;

            if (!this.validation.productId.isInvalid && !this.options.products[this.productId]) {
                this.validation.productId.isInvalid = true;
                this.productId = null;
            }
        },
        updateCountriesFilter() {
            const form = this.formId ? this.options.forms.find(value => value.id === this.formId) : null;
            this.showCountriesFilter = form && form.is_country_related;

            if (!this.showCountriesFilter) {
                this.countryId = null;
            }
        },
        reset() {
            this.metalId = null;
            this.formId = null;
            this.finenessId = null;
            this.securityFeatureId = null;
            this.weightId = null;
            this.productId = null;
            this.countryId = null;
            this.loadOptions();
            this.markAllAsTouched(false)
        },
        save() {
            this.markAllAsTouched();

            if (this.isFormValid()) {
                window.location.href = '/' + this.locale + '/offers/create/' + this.productId;
            }
        },
        markAllAsTouched(isTouched = true) {
            Object.keys(this.validation).forEach(field => {
                this.validation[field]['isTouched'] = isTouched;
            });
        },
        isFormValid() {
            let isValid = true;

            Object.keys(this.validation).forEach(field => {
                if (this.validation[field]['isInvalid']) {
                    isValid = false;
                }
            });

            return isValid;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
