<template>
    <div v-if="isInitialized && translations && options">
        <div class="filter-inputs" :class="{'p-3 border-top': isMobile}">
            <product-autocomplete :csrf-token="csrfToken"
                                  :locale="locale"
                                  :placeholder="translations.PRODUCTS_AUTOCOMPLETE_PLACEHOLDER"
                                  :initial-value="filters.productName.selected"
                                  @selectProduct="selectProduct($event)"></product-autocomplete>
            <div class="mt-3" :class="{accordion: !isMobile}" id="offerFilters">
                <offer-filter v-model:filters="filters.metals"
                              :label="translations.METALS"
                              :options="options.metals"
                              :translations="translations"
                              :is-mobile="isMobile"
                              table="metals"></offer-filter>
                <offer-filter v-model:filters="filters.forms"
                              :label="translations.FORMS"
                              :options="options.forms"
                              :translations="translations"
                              :is-mobile="isMobile"
                              table="forms"></offer-filter>
                <offer-filter v-model:filters="filters.countries" v-if="isSelectedFormCountryRelated"
                              :label="translations.COUNTRIES"
                              :options="options.countries"
                              :translations="translations"
                              :is-mobile="isMobile"
                              table="countries"></offer-filter>
                <offer-filter v-model:filters="filters.weights"
                              :label="translations.WEIGHTS"
                              :options="options.weights"
                              :translations="translations"
                              :unit="translations.GRAM_UNIT"
                              :is-mobile="isMobile"
                              table="weights"></offer-filter>
                <offer-filter v-model:filters="filters.finenesses"
                              :label="translations.FINENESSES"
                              :options="options.finenesses"
                              :translations="translations"
                              :is-mobile="isMobile"
                              table="finenesses"></offer-filter>
                <offer-filter v-model:filters="filters.securityFeatures"
                              :label="translations.SECURITY_FEATURES"
                              :options="options.securityFeatures"
                              :translations="translations"
                              :is-mobile="isMobile"
                              table="securityFeatures"></offer-filter>
                <offer-filter v-model:filters="filters.prices"
                              :label="translations.PRICES"
                              :options="options.prices"
                              :translations="translations"
                              :unit="translations.CURRENCY_UNIT"
                              :is-mobile="isMobile"
                              table="prices"></offer-filter>
                <offer-filter v-model:filters="filters.pricesPerGram"
                              :label="translations.PRICES_PER_GRAM"
                              :options="options.pricesPerGram"
                              :translations="translations"
                              :unit="translations.CURRENCY_UNIT"
                              :is-mobile="isMobile"
                              table="pricesPerGram"></offer-filter>
                <offer-filter v-model:filters="filters.offerTypes"
                              :label="translations.OFFER_TYPES"
                              :options="options.offerTypes"
                              :translations="translations"
                              :is-mobile="isMobile"
                              table="offerTypes"></offer-filter>
                <offer-filter v-model:filters="filters.distances" v-if="isUserLoggedIn"
                              :label="translations.DISTANCES"
                              :options="options.distances"
                              :translations="translations"
                              :is-mobile="isMobile"
                              table="distances"></offer-filter>
                <div :class="{card: !isMobile, 'border-top pt-4 mb-4': isMobile}">
                    <div :class="{'card-body': !isMobile}">
                        <div class="form-check">
                            <input id="certificate" class="form-check-input" type="checkbox"
                                   v-model="filters.checkboxes.certificate">
                            <label class="form-check-label" for="certificate">
                                {{ translations.CERTIFICATE }}
                            </label>
                        </div>
                        <div class="form-check">
                            <input id="receipt" class="form-check-input" type="checkbox"
                                   v-model="filters.checkboxes.reciept">
                            <label class="form-check-label" for="receipt">
                                {{ translations.RECEIPT }}
                            </label>
                        </div>
                        <div class="form-check">
                            <input id="dutyfree_stored" class="form-check-input" type="checkbox"
                                   v-model="filters.checkboxes.dutyfree_stored">
                            <label class="form-check-label" for="dutyfree_stored">
                                {{ translations.DUTYFREE_STORED }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-buttons" :class="{'border-top pl-3 pr-3': isMobile}">
            <button class="btn btn-outline-secondary" @click="cancel">
                {{ translations.RESET }}
            </button>
            <button class="btn btn-secondary" @click="search">
                {{ translations.SEARCH }}
            </button>
        </div>
    </div>
</template>

<script>
import OfferFilter from "@/components/OfferFilter";
import ProductAutocomplete from "@/components/ProductAutocomplete";

export default {
    name: 'OfferFilters',
    components: {ProductAutocomplete, OfferFilter},
    data() {
        return {
            isInitialized: false,
            translations: null,
            options: null,
            isSelectedFormCountryRelated: false,
            filterRequestProperties: {latestRequestBody: null, latestAttemptTimestamp: null},
            filters: {
                productName: {selected: null, type: 'string'},
                metals: {count: 0, selected: null, type: 'dropdown'},
                forms: {count: 0, selected: null, type: 'dropdown'},
                countries: {count: 0, selected: null, type: 'dropdown'},
                finenesses: {count: 0, selected: null, type: 'dropdown'},
                offerTypes: {count: 0, selected: null, type: 'dropdown'},
                distances: {count: 0, selected: null, type: 'dropdown'},
                securityFeatures: {count: 0, selected: null, type: 'dropdown'},
                weights: {count: 0, selected: {min: null, max: null}, step: 0.1, type: 'range'},
                prices: {count: 0, selected: {min: null, max: null}, step: 1, type: 'range'},
                pricesPerGram: {count: 0, selected: {min: null, max: null}, step: 1, type: 'range'},
                checkboxes: {certificate: false, reciept: false, dutyfree_stored: false},
                touchStartY: 0
            }
        };
    },
    props: ['csrfToken', 'sessionId', 'locale', 'isUserLoggedIn', 'isMobile'],
    created() {
        this.loadTranslations();
        this.loadFilterOptions().then(() => this.loadInitialFilters());
    },
    methods: {
        selectProduct(product) {
            const updatedFilters = this.filters;

            if (product) {
                updatedFilters.productName.selected = product.name
                updatedFilters.metals.selected = product.metal_id;
                updatedFilters.forms.selected = product.form_id;
                updatedFilters.countries.selected = product.country_id;
                updatedFilters.finenesses.selected = product.fineness_id;
                updatedFilters.securityFeatures.selected = product.security_feature_id;
                updatedFilters.weights.selected.min = product.weight.weight_in_gram;
                updatedFilters.weights.selected.max = product.weight.weight_in_gram;

            } else if(updatedFilters.productName.selected) {
                updatedFilters.productName.selected = null
                updatedFilters.metals.selected = null;
                updatedFilters.forms.selected = null;
                updatedFilters.countries.selected = null;
                updatedFilters.finenesses.selected = null;
                updatedFilters.securityFeatures.selected = null;
                updatedFilters.weights.selected.min = null;
                updatedFilters.weights.selected.max = null;
            }else {
                return;
            }

            this.filters = updatedFilters;
            this.search();
        },
        updateFilters(body) {
            if (this.filterRequestProperties.latestRequestBody === body) {
                return;
            }

            const timestamp = new Date().getTime();
            this.filterRequestProperties.latestAttemptTimestamp = timestamp;

            setTimeout(() => {
                if (this.filterRequestProperties.latestAttemptTimestamp === timestamp
                    && this.filterRequestProperties.latestRequestBody !== body) {
                    this.loadFilterOptions(body);
                    this.filterRequestProperties.latestRequestBody = body;
                }

            }, 300);
        },
        loadTranslations() {
            fetch('/' + this.locale + '/offers-filter/offer-filter-translations.json', {
                method: 'GET',
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        if (body.success) {
                            this.translations = body.data;
                        }
                    });
                }
            });
        },
        loadInitialFilters() {
            fetch('/' + this.locale + '/offers-filter/get-active-filters.json', {
                method: 'GET',
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        if (body.success) {
                            this.setInitialActiveFilters(body.data);
                            this.isInitialized = true;
                        }
                    });
                }
            });
        },
        setInitialActiveFilters(filters) {
            const updatedFilters = this.filters;

            Object.keys(updatedFilters).forEach(key => {
                const filter = filters[key];

                if (filter) {
                    if (updatedFilters[key].type === 'dropdown' || updatedFilters[key].type === 'min') {
                        updatedFilters[key].selected = filter

                        if (filter) {
                            updatedFilters[key].count = 1;
                        }

                    } else if (updatedFilters[key].type === 'range') {
                        updatedFilters[key].selected = filter
                        const rangeOption = this.options[key];

                        if ((filter.min == null || filter.min === rangeOption.min) && (filter.max == null || filter.max === rangeOption.max)) {
                            updatedFilters[key].count = 0;
                        } else {
                            updatedFilters[key].count = 1;
                        }

                    } else if (updatedFilters[key].type === 'string') {
                        updatedFilters[key].selected = filter;

                    } else {
                        updatedFilters[key] = filter;
                    }
                }
            });

            this.filters = updatedFilters;
        },
        loadFilterOptions(body = null) {
            return fetch('/' + this.locale + '/offers-filter/offer-filter-options.json', {
                method: body ? 'POST' : 'GET',
                body: body,
                headers: {
                    'X-CSRF-Token': this.csrfToken,
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.ok) {
                    res.json().then(body => {
                        if (body.success) {
                            this.options = body.data;
                            this.initControlValues();
                        }
                    });
                }
            });
        },
        buildRequestBody() {
            const body = {};

            Object.keys(this.filters).forEach(key => {
                if (key !== 'checkboxes') {
                    body[key] = this.filters[key].selected;
                }
            });

            Object.keys(this.filters.checkboxes).forEach(key => {
                body[key] = this.filters.checkboxes[key];
            });

            return JSON.stringify(body);
        },
        initControlValues() {
            Object.keys(this.filters).forEach(key => {
                const selected = this.filters[key].selected;
                const options = this.options[key];

                if (this.filters[key].type === 'dropdown' && selected) {
                    const selectedOption = options.find(option => option.id === selected);
                    if (!selectedOption) {
                        this.filters[key].selected = null;
                        this.filters[key].count = 0;
                    }

                } else if (this.filters[key].type === 'min') {
                    if (options.min === null) {
                        this.filters[key].selected.min = null;
                    }

                } else if (this.filters[key].type === 'range') {
                    selected.min = selected.min ? parseFloat(selected.min) : null;
                    selected.max = selected.max ? parseFloat(selected.max) : null;

                    if (options.min === null) {
                        this.filters[key].selected.min = null;
                    } else if (selected.min !== null && (selected.min < options.min || selected.min > options.max)) {
                        selected.min = options.min;
                    }

                    if (options.max === null) {
                        this.filters[key].selected.max = null;
                    } else if (selected.max !== null && (selected.max < options.min || selected.max > options.max)) {
                        selected.max = options.max;
                    }
                }
            });
        },
        search() {
            // create hidden input form
            const form = document.createElement('form');
            form.setAttribute('method', 'post');
            form.setAttribute('_csrf', this.csrfToken);

            // add inputs to form
            Object.keys(this.filters).forEach(key => {
                const input = document.createElement('input');

                input.setAttribute('type', 'hidden');
                input.setAttribute('name', key);

                let value = this.filters[key];
                if (value && 'selected' in value) {
                    value = this.filters[key].selected;
                }
                input.setAttribute('value', JSON.stringify(value));

                form.appendChild(input);
            });

            // add csrf token
            const csrf = document.createElement('input');
            csrf.setAttribute('type', 'hidden');
            csrf.setAttribute('name', '_csrfToken');
            csrf.setAttribute('value', this.csrfToken);
            form.appendChild(csrf);

            // add form to body and submit
            document.body.appendChild(form);
            form.submit();
        },
        cancel() {
            window.location.href = '/' + this.locale + '/offers-filter/reset-offer-filters';
        }
    },
    watch: {
        selectedForm(value) {
            const selected = this.options.forms.find(form => form.id === value);
            this.isSelectedFormCountryRelated = selected && selected.isCountryRelated;

            if (!this.isSelectedFormCountryRelated) {
                this.filters.countries.selected = null;
            }
        },
        filtersString() {
            this.updateFilters(this.buildRequestBody());
        }
    },
    computed: {
        selectedForm() {
            return this.filters.forms.selected;
        },
        filtersString() {
            return JSON.stringify(this.filters);
        }
    }
}
</script>

<style scoped>
</style>
