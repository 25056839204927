<template>
    <offer-form v-if="offer"
                :allowed-image-types="allowedImageTypes"
                :max-file-size="maxFileSize"
                :offer="offer"
                :save-handler="handleCreateOffer"
                :locale="locale"></offer-form>
</template>

<script>
import OfferForm from "../OfferForm";
import OffersApi from "../../services/OffersApi";

export default {
    name: 'CreateOffer',
    components: {OfferForm},
    data() {
        return {
            offer: null
        };
    },
    props: [
        'locale',
        'allowedImageTypes',
        'maxFileSize',
        'productId',
        'csrfToken'
    ],
    created() {
        this.offer = {product_id: this.productId};
    },
    methods: {
        handleCreateOffer(offerData) {
            return OffersApi.createOffer(offerData, this.locale, this.csrfToken).then((response) => {
                return response.json();
            });
        }
    }
};
</script>

<style scoped>
</style>
