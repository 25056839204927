<script>

import ConsentSettingsLink from "./components/ConsentSettingsLink";
import ImageUpload from "./components/ImageUpload";
import OfferBids from "./components/OfferBids";
import OfferCountdown from "./components/OfferCountdown";
import OfferSelectProductForm from "./components/OfferSelectProductForm";
import QuestionsToggle from "./components/QuestionsToggle";
import BookmarkToggle from "@/components/BookmarkToggle";
import RangeSlider from "./components/RangeSlider";
import OfferFilterInput from "@/components/OfferFilterInput";
import OfferFilter from "@/components/OfferFilter";
import OfferFilters from "@/components/OfferFilters";
import AddressSelection from "@/components/AddressSelection";
import Autocomplete from "@/components/Autocomplete";
import IdentityDocumentUpload from "@/components/IdentityDocumentUpload";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import ProductAutocomplete from "@/components/ProductAutocomplete";
import Sidebar from "@/components/Sidebar";
import Sentry from "@/components/Sentry";
import OfferForm from "./components/OfferForm";
import RenewOffer from "./components/Offers/RenewOffer";
import CreateOffer from "./components/Offers/CreateOffer";
import EditOffer from "./components/Offers/EditOffer";

export default {
    name: 'App',
    components: {
        BookmarkToggle,
        ConsentSettingsLink,
        ImageUpload,
        OfferBids,
        OfferCountdown,
        OfferSelectProductForm,
        RangeSlider,
        OfferFilterInput,
        OfferFilter,
        OfferFilters,
        QuestionsToggle,
        AddressSelection,
        Autocomplete,
        IdentityDocumentUpload,
        PhoneNumberInput,
        ProductAutocomplete,
        Sidebar,
        OfferForm,
        RenewOffer,
        EditOffer,
        CreateOffer,
        Sentry
    },
}
</script>

<style>
</style>
