<template>
    <div class="alert alert-danger" v-if="error_message">
        {{ error_message }}
    </div>
    <div v-if="options">
        <div class="row" v-if="!editMode">
            <div class="col-lg-10 col-xl-10">
                <div class="form-group">
                    <label for="title">{{ options.translations.form.TITLE }}</label>
                    <input type="text" id="title" class="form-control" minlength="6" maxlength="255"
                           required ref="form.title"
                           v-model="form.title" @change="validateField($event, 'title')"
                           v-bind:class="{'is-invalid': !validation.title.isValid && validation.title.isTouched}">
                    <div class="invalid-feedback d-block"
                         v-if="!validation.title.isValid && validation.title.isTouched">
                        {{ options.translations.errorMessages.TITLE }}
                    </div>
                </div>
            </div>
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label for="number_of_products">{{ options.translations.form.NUMBER_OF_PRODUCTS }}</label>
                    <input id="number_of_products" type="number" step="1" required min="1" class="form-control"
                           v-model="form.number_of_products" ref="form.number_of_products"
                           @change="validateField($event, 'number_of_products')"
                           v-bind:class="{'is-invalid': !validation.number_of_products.isValid && validation.number_of_products.isTouched}">
                    <div class="invalid-feedback d-block"
                         v-if="!validation.number_of_products.isValid && validation.number_of_products.isTouched">
                        {{ options.translations.errorMessages.NUMBER_OF_PRODUCTS }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!editMode">
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label for="text">{{ options.translations.form.DESCRIPTION }}</label>
                    <textarea id="text" class="form-control" rows="5" v-model="form.text" required minlength="10"
                              maxlength="511"
                              @change="validateField($event, 'text')"
                              ref="form.text"
                              v-bind:class="{'is-invalid': !validation.text.isValid && validation.text.isTouched}"></textarea>
                    <div class="invalid-feedback d-block" v-if="!validation.text.isValid && validation.text.isTouched">
                        {{ options.translations.errorMessages.DESCRIPTION }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!editMode">
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label>{{ options.translations.form.EXTRA_FEATURES }}</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input id="certificate" class="form-check-input" type="checkbox" v-model="form.certificate">
                            <label for="certificate" class="form-check-label">
                                {{ options.translations.form.CERTIFICATE_PRESENT }}
                            </label>
                        </div>
                        <br class="display-mobile">
                        <div class="form-check form-check-inline">
                            <input id="receipt" class="form-check-input" type="checkbox" v-model="form.receipt">
                            <label for="receipt" class="form-check-label">
                                {{ options.translations.form.RECEIPT_PRESENT }}
                            </label>
                        </div>
                        <br class="display-mobile">
                        <div class="form-check form-check-inline">
                            <input id="dutyfree_stored" class="form-check-input" type="checkbox"
                                   v-model="form.dutyfree_stored">
                            <label for="dutyfree_stored" class="form-check-label">
                                {{ options.translations.form.DUTYFREE_STORED }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!editMode">
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label>{{ options.translations.form.PICTURES }}</label>
                    <image-upload @changeFiles="validateImages($event)" :pictures="form.pictures" :locale="locale"
                                  :file-types="allowedImageTypes"
                                  upload-action="upload.json"
                                  :max-size-per-image-kb="maxFileSize"></image-upload>
                    <div class="invalid-feedback d-block"
                         v-if="!validation.images.isValid && validation.images.isTouched">
                        {{ options.translations.errorMessages.PICTURES }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!editMode">
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label for="type">
                        {{ options.translations.form.OFFER_TYPE }}
                        <span v-tooltip:top="options.translations.form.OFFER_TYPE_TOOLTIP">
                            <i class="fa fa-info-circle"></i>
                        </span>
                    </label>
                    <select id="type" class="form-control" v-model="form.offer_type" @change="offerTypeChanged($event)">
                        <option :value="value" :key="key" v-for="(key, value) in options.options.offerTypes">{{ key }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label for="planned_end_of_offer">
                        {{ options.translations.form.PLANNED_END }}
                    </label>
                    <input id="planned_end_of_offer" class="form-control" type="datetime-local" :min="min_date"
                           :max="max_date" step="60"
                           v-model="form.planned_end_of_offer"
                           ref="form.planned_end_of_offer"
                           @change="validateField($event, 'planned_end_of_offer')"
                           v-bind:class="{'is-invalid': !validation.planned_end_of_offer.isValid && validation.planned_end_of_offer.isTouched}">
                    <div class="invalid-feedback">{{ options.translations.errorMessages.PLANNED_END_DATE }}</div>
                </div>
            </div>
        </div>
        <div class="row" v-if="form.offer_type === 'FIXED_PRICE'">
            <div class="col-lg-6 col-xl-6">
                <div class="form-group">
                    <label for="min_price">
                        {{ options.translations.form.PRICE }}
                    </label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <label for="min_price" class="input-group-text">
                                {{ options.translations.form.CHF }}
                                <span v-tooltip:top="options.translations.form.PRICE_TOOLTIP" class="ml-1">
                                    <i class="fa fa-info-circle"></i>
                                </span>
                            </label>
                        </div>
                        <input id="min_price" class="form-control" type="number" step="0.01" required min="1.00"
                               max="99999999"
                               v-model="form.min_price"
                               ref="form.min_price"
                               @change="minPriceChanged($event)"
                               v-bind:class="{'is-invalid': !validation.min_price.isValid && validation.min_price.isTouched}">
                        <div class="invalid-feedback d-block"
                             v-if="!validation.min_price.isValid && validation.min_price.isTouched">
                            {{ options.translations.errorMessages.MIN_PRICE }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="form.offer_type === 'AUCTION'">
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label for="min_price">
                        {{ options.translations.form.MINIMUM_PRICE }}
                        <span v-tooltip:top="options.translations.form.MINIMUM_PRICE_TOOLTIP">
                            <i class="fa fa-info-circle"></i>
                        </span>
                    </label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <label for="min_price" class="input-group-text">
                                {{ options.translations.form.CHF }}
                                <span v-tooltip:top="options.translations.form.PRICE_TOOLTIP" class="ml-1">
                                    <i class="fa fa-info-circle"></i>
                                </span>
                            </label>
                        </div>
                        <input id="min_price" class="form-control" type="number" step="0.01" required min="1.00"
                               max="99999999"
                               v-model="form.min_price" ref="form.min_price"
                               @change="validateField($event, 'min_price')"
                               v-bind:class="{'is-invalid': !validation.min_price.isValid && validation.min_price.isTouched}">
                        <div class="invalid-feedback d-block"
                             v-if="!validation.min_price.isValid && validation.min_price.isTouched">
                            {{ options.translations.errorMessages.MIN_PRICE }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label for="max_price">
                        {{ options.translations.form.MAXIMUM_PRICE }}
                        <span v-tooltip:top="options.translations.form.MAXIMUM_PRICE_TOOLTIP">
                            <i class="fa fa-info-circle"></i>
                        </span>
                    </label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <label for="max_price" class="input-group-text">
                                {{ options.translations.form.CHF }}
                                <span v-tooltip:top="options.translations.form.PRICE_TOOLTIP" class="ml-1">
                                    <i class="fa fa-info-circle"></i>
                                </span>
                            </label>
                        </div>
                        <input id="max_price" class="form-control" type="number" v-model="form.max_price" max="99999999"
                               @change="validateField($event, 'max_price')"
                               ref="form.max_price"
                               v-bind:class="{'is-invalid': !validation.max_price.isValid && validation.max_price.isTouched}">
                        <div class="invalid-feedback d-block"
                             v-if="!validation.max_price.isValid && validation.max_price.isTouched">
                            {{ options.translations.errorMessages.MAX_PRICE }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!editMode">
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label>{{ options.translations.form.PICKUP_DELIVERY }}</label>
                    <select class="form-control" v-model="form.delivery_pickup" required
                            ref="form.delivery_pickup"
                            @change="validateField($event, 'delivery_pickup')"
                            v-bind:class="{'is-invalid': !validation.delivery_pickup.isValid && validation.delivery_pickup.isTouched}">
                        <option :value="key" :key="key" v-for="(label, key) in options.options.deliveryTypes">
                            {{ label }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-lg col-xl">
                <div class="form-group">
                    <label>{{ options.translations.form.PAYMENT_TYPE }}</label>
                    <div>
                        <div class="form-check form-check-inline" :key="key"
                             v-for="(label, key) in options.options.paymentTypes">
                            <input :id="key" class="form-check-input" type="checkbox" v-model="form.payment_types"
                                   :value="key" @change="validatePaymentTypes()">
                            <label :for="key" class="form-check-label">{{ label }}</label>
                        </div>
                    </div>
                    <div class="invalid-feedback d-block"
                         v-if="!validation.payment_types.isValid && validation.payment_types.isTouched">
                        {{ options.translations.errorMessages.PAYMENT_TYPES }}
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-buttons">
            <button class="btn btn-outline-secondary" @Click="back()">
                {{ options.translations.form.BACK }}
            </button>
            <button class="btn btn-secondary " type="submit" @Click="submit()" v-if="!this.editMode">
                {{ options.translations.form.CREATE }}
            </button>
            <button class="btn btn-secondary " type="submit" @Click="submit()" v-if="this.editMode">
                {{ options.translations.form.UPDATE }}
            </button>
        </div>
    </div>
    <loading-screen :is-loading="isLoading"></loading-screen>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import LoadingScreen from "@/components/LoadingScreen";
import OffersApi from "../services/OffersApi";

export default {
    name: 'OfferForm',
    components: {LoadingScreen, ImageUpload},
    data() {
        const formDefaults = {
            title: '',
            text: '',
            offer_type: 'FIXED_PRICE',
            certificate: false,
            receipt: false,
            dutyfree_stored: false,
            min_price: 0,
            max_price: null,
            planned_end_of_offer: null,
            delivery_pickup: null,
            number_of_products: 1,
            payment_types: '',
            min_date: null,
            max_date: null,
        };

        //Quickfix for migrating the single payment method to two payment methods
        let formValue = Object.assign(formDefaults, this.offer);
        formValue.payment_types = this.offer.payment_type || [];
        delete formValue.payment_type;

        // Set price data to defaults, when its not the edit mode
        if (!this.editMode) {
            formValue.min_price = 0;
            formValue.max_price = null;
        }

        return {
            // Loading spinner control
            isLoading: false,

            // Translations for error messages and labels
            options: null,

            // Error message display
            error_message: null,

            // override the form defaults with passed data
            form: formValue,

            pictures: this.offer.pictures ?? '',
            images: [],

            // Validation state
            validation: {
                title: {isValid: false, isTouched: false},
                number_of_products: {isValid: true, isTouched: false},
                text: {isValid: false, isTouched: false},
                images: {isValid: false, isTouched: false},
                min_price: {isValid: false, isTouched: false},
                max_price: {isValid: true, isTouched: false},
                planned_end_of_offer: {isValid: true, isTouched: false},
                payment_types: {isValid: false, isTouched: false},
                delivery_pickup: {isValid: false, isTouched: false},
            }
        };
    },
    props: ['locale', 'offer', 'allowedImageTypes', 'maxFileSize', 'saveHandler', 'editMode'],
    created() {
        this.initOptions();
        this.initData();
    },
    methods: {
        initData() {
            const timestamp = Date.now() - this.getLocalOffset();
            const day = 1000 * 60 * 60 * 24;

            // Calculate possible min and max end dates for the offer
            this.min_date = new Date(timestamp + day).toISOString().substring(0, 16);
            this.max_date = new Date(timestamp + day * 21).toISOString().substring(0, 16);

            // set the end date to now in 14 days
            this.form.planned_end_of_offer = this.max_date;
        },
        initOptions() {
            // Fetch translated labels and error messages
            OffersApi.getCreateOptions(this.locale).then(body => this.options = body);
        },
        getLocalOffset() {
            return 1000 * 60 * new Date().getTimezoneOffset();
        },
        updateValidity() {
            const inputValidationFields = [
                "title",
                "number_of_products",
                "text",
                "planned_end_of_offer",
                "delivery_pickup",
            ];

            for (let i = 0; i < inputValidationFields.length; i++) {
                let field = inputValidationFields[i];

                let valid = false;

                if (this.editMode) {
                    // These fields cannot be changed in editmode, so they must be set to valid.
                    valid = true;
                } else {
                    let input = this.$refs["form." + field];
                    valid = input.checkValidity();
                }

                this.validation[field] = {
                    isValid: valid,
                    isTouched: true
                };
            }


            // Validate prices
            this.validateMinPrice();
            this.validateMaxPrice();

            // These fields cannot be changed in editmode, so they must be set to valid.
            if (this.editMode) {
                this.validation['payment_types'] = {
                    isValid: true,
                    isTouched: true
                };
                this.validation['images'] = {
                    isValid: true,
                    isTouched: true
                };
            } else {
                // Validate the PaymentTypes, when its not the editmode
                this.validatePaymentTypes();
            }

        },
        submit() {
            this.updateValidity();

            this.error_message = null;

            if (this.isFormValid() && !this.isLoading) {
                this.isLoading = true;

                //Use saveHandler to handle the save request so this component
                //can be used for different aspects, after trying to save disable the loading spinner again
                this.saveHandler(this.buildRequestBody())
                    .then((response_body) => {
                        if (response_body.data) {
                            window.location.href = '/offers/view/' + response_body.data.id;
                        } else {
                            // Set error message if there is any
                            this.error_message = response_body.message;
                        }
                    })
                    .finally(() => this.isLoading = false);
            }
        },
        buildRequestBody() {
            const plannedEnd = new Date(Date.parse(this.form.planned_end_of_offer)).toISOString();
            this.form.payment_type = this.form.payment_types;

            return Object.assign({}, this.form, {images: this.images, planned_end_of_offer: plannedEnd});
        },
        validateField(e, fieldName) {
            // Special price validation first
            if (fieldName === 'min_price') {
                this.validateMinPrice(e);

                if (this.form.offer_type === 'AUCTION') {
                    this.validateMaxPrice();
                }

                this.setValidation(fieldName, this.$refs['form.min_price'].checkValidity());
            } else if (fieldName === 'max_price') {
                this.validateMaxPrice(e);
            } else {
                // Standard validation based on html inputs
                this.setValidation(fieldName, e.target.checkValidity());
            }
        },
        setValidation(field, isValid, isTouched = true) {
            this.validation[field] = {isValid, isTouched};
        },
        validateImages(files) {
            this.images = files;
            if (this.images.length > 0) {
                this.setValidation('images', true);
            } else {
                this.setValidation('images', false);
            }
        },
        validateMaxPrice(e) {
            let value = e ? e.target.value : this.form.max_price;

            if (value === null || value === '') {
                this.setValidation('max_price', true);
                return;
            }

            value = this.parsePrice(value.toString());
            this.form.max_price = value;

            const minPrice = parseFloat(this.form.min_price);
            const maxPrice = parseFloat(this.form.max_price);
            const isValid = isNaN(maxPrice) || maxPrice >= minPrice;

            this.setValidation('max_price', isValid);
        },
        validateMinPrice(e) {
            let value = e ? e.target.value : this.form.min_price;

            if (value === null || value === '') {
                this.setValidation('min_price', false);
                return;
            }

            value = this.parsePrice(value.toString());
            this.form.min_price = value;

            if(this.form.offer_type === 'FIXED_PRICE') {
                // For fixed price offers min and max prices are the same
                this.form.max_price = this.form.min_price;
            }

            const minPrice = parseFloat(this.form.min_price);
            this.setValidation('min_price', !isNaN(minPrice) && minPrice >= 1.0);
        },
        offerTypeChanged(e) {
            let offerType = e ? e.target.value : this.form.offer_type;

            if(offerType === 'FIXED_PRICE') {
                this.form.max_price = this.form.min_price;
            }
        },
        minPriceChanged(e) {
            this.validateMinPrice(e);

            if(this.editMode && this.form.offer_type === 'FIXED_PRICE') {
                // If the offer is being edited and the offer is a fixed price offer
                // automatically raise the min_price (offer is FIXED_PRICE if min and max prices are the same, this is an invariant)
                this.validateMaxPrice(e)
            }
        },
        validatePaymentTypes() {
            if (Object.keys(this.form.payment_types).length > 0) {
                this.setValidation('payment_types', true);
            } else {
                this.setValidation('payment_types', false);
            }
        },
        isFormValid() {
            let isValid = true;
            Object.keys(this.validation).forEach(field => {
                this.validation[field]['isTouched'] = true;

                if (!this.validation[field]['isValid']) {
                    isValid = false;
                }
            });
            return isValid;
        },
        parsePrice(price) {
            price = price ? parseFloat(price).toFixed(1) + '0' : null;
            price.replace(".", ",");
            return price;
        },
        back() {
            if (this.editMode) {
                // If it is the edit mode, redirect back to the offer
                window.location.href = '/' + this.locale + '/offers/view/' + this.offer.id;
            } else {
                if (confirm(this.options.translations.form.BACK_MESSAGE).valueOf()) {

                    window.location.href = '/' + this.locale + '/offers/init';
                }
            }
        }
    }
};
</script>

<style scoped>
</style>
